<template>
    <div class="activations-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="activations-list row g-2 mb-4">
                <div class="col-md-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search activations"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-md-auto">
                    <date-range-picker
                        ref="picker"
                        v-model="range"
                        opens="right"
                        time-picker
                        time-picker-24-hours
                        show-dropdowns
                        control-container-class="activations-list__date-range-picker"
                        :linked-calendars="false"
                        @update="changeDate"
                    >
                        <template #input>
                            <em class="fa fa-fw fa-calendar ml-n1"></em>
                            {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                            {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                            <strong class="caret"></strong>
                        </template>
                    </date-range-picker>
                </div>
                <div class="col-md-2">
                    <ui-select
                        id="activationType"
                        name="activationType"
                        label="Type"
                        class="w-100"
                        option-label="label"
                        option-value="value"
                        :options="type"
                        v-model:value="filters.type"
                    />
                </div>
                <div class="col-md-2">
                    <ui-select-multiple
                        id="activationStatus"
                        name="activationStatus"
                        label="Status"
                        class="w-100"
                        option-label="label"
                        option-value="value"
                        :options="status"
                        v-model:value="filters.status"
                    />
                </div>
            </div>
            <ui-table class="mb-4" :items="activations" :fields="fields" :busy="busy" v-model:sort-by="filters.sort_by" v-model:sort-type="filters.sort_type" :scopes="['read_activations', 'write_activations']">
                <template #cell(account)="data">
                    <span v-if="!data.item.account || Object.keys(data.item.account).length == 0" class="badge bg-light text-dark">
                        No information
                    </span>
                    <span v-else >
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600"> {{ data.item.account.first_name }} {{ data.item.account.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link v-if="data.item.account.email" underline weight="regular" :link="'mailto:' + data.item.account.email">
                                {{ data.item.account.email }}
                            </ui-link>
                            <ui-link v-else-if="data.item.account.phone" underline weight="regular" :link="`tel:+${data.item.account.phone}`">
                                {{ data.item.account.phone }}
                            </ui-link>
                        </div>
                    </span>
                </template>
                <template #cell(type)="data">
                    <span v-if="!data.item.type" class="badge bg-light text-dark">
                        Unassigned
                    </span>
                    <span  v-else class="text-capitalize mx-2"> {{ data.item.type }}</span>
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>
                <template #cell(updated_at)="data">
                    {{ $dayjs(data.item.updated_at).format('LL') }} <br />
                    {{ $dayjs(data.item.updated_at).format('LTS') }}
                </template>
                <template #cell(status)="data">
                    <span v-if="!data.item.status" class="badge bg-light text-dark">
                        No information
                    </span>
                    <ui-status v-else
                        class="d-inline-flex align-items-center text-capitalize fs-13px"
                        :status="data.item.status"
                    />
                </template>
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <span class="fas fa-ellipsis-v"></span>
                        </template>
                        <b-dropdown-item :link="`/activations/info?id=${data.item.id}`">
                            See details
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiSelect from '@/components/ui/Select';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiTable from '@/components/ui/Table.vue';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import { filters as data_filters } from '@/data';
import table_mixin from '@/mixins/tables.mixin';

export default {
    components: {
        DateRangePicker,
        UiInput,
        UiLink,
        UiPagination,
        UiSelect,
        UiSelectMultiple,
        UiTable,
        UiStatus,
        UiTableSkeleton,
    },
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            busy: false,
            activations: null,
            creating: false,
            fields: [
                { key: 'account', label: 'Account' },
                { key: 'type', label: 'Type' },
                { key: 'created_at', label: 'Created At' },
                { key: 'updated_at', label: 'Updated At', sortable: true },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                range: { ...range },
                sort_by: null,
                sort_type: null,
            },
            inititialLoading: true,
            loading: false,
            range: { ...range },
            total_pages: null,
        };
    },
    computed: {
        type() {
            return data_filters.activation.type;
        },
        status() {
            return data_filters.activation.status;
        }
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getActivations);
            },
        },
    },
    methods: {
        changeDate(data) {
            this.filters.range = data;
        },
        async getActivations() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    'created_at[from]': this.$dayjs(this.filters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(this.filters.range.endDate).toISOString(),
                    type: this.filters.type,
                    status: this.filters.status,
                    sort_by: this.filters.sort_by,
                    sort_type: this.filters.sort_type,
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/activations', { params });
                this.total_pages = data.pages;
                this.activations = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
    },
};
</script>

<style lang="scss">
.activations-list {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>